// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FileList_box__drd4I {\n    width: calc(100vw - 1rem);\n    max-width: 40rem;\n    min-width: 25rem;\n}\n\n.FileList_grid__4U6C2 {\n    margin-bottom: 1rem;\n}\n\n.FileList_fileItem__dFgVe {\n    height: 56px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background: #efefef;\n    border-radius: 5px;\n}\n\n.FileList_fileItem__dFgVe:nth-of-type(2n) {\n    background: inherit;\n}\n\n.FileList_fileOffset__brNlf {\n    padding-left: .5rem;\n}\n\n.FileList_fileName__oS\\+XZ {\n    line-height: 40px;\n    text-align: center;\n    text-overflow: ellipsis;\n    overflow: hidden;\n    white-space: nowrap;\n}", "",{"version":3,"sources":["webpack://./src/components/FileList.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,uBAAuB;IACvB,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":[".box {\n    width: calc(100vw - 1rem);\n    max-width: 40rem;\n    min-width: 25rem;\n}\n\n.grid {\n    margin-bottom: 1rem;\n}\n\n.fileItem {\n    height: 56px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background: #efefef;\n    border-radius: 5px;\n}\n\n.fileItem:nth-of-type(2n) {\n    background: inherit;\n}\n\n.fileOffset {\n    padding-left: .5rem;\n}\n\n.fileName {\n    line-height: 40px;\n    text-align: center;\n    text-overflow: ellipsis;\n    overflow: hidden;\n    white-space: nowrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": "FileList_box__drd4I",
	"grid": "FileList_grid__4U6C2",
	"fileItem": "FileList_fileItem__dFgVe",
	"fileOffset": "FileList_fileOffset__brNlf",
	"fileName": "FileList_fileName__oS+XZ"
};
export default ___CSS_LOADER_EXPORT___;
