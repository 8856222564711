import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import GlobalStyles from '@mui/material/GlobalStyles'
import bgImage from './images/bg-gtinnovation.jpg';

ReactDOM.render(
    <React.StrictMode>
        <GlobalStyles styles={{
            body: {
                margin: 0,
                backgroundImage: `url(${bgImage})`,
                backgroundSize: 'cover', // Cover will resize the background image to cover the entire container while maintaining aspect ratio
                backgroundPosition: 'center', // Center the background image
                backgroundRepeat: 'no-repeat', // Prevent the background image from repeating

            }
        }}/>
        <App/>
    </React.StrictMode>,
    document.getElementById('root')
)