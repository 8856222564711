import React from 'react'
import PropTypes from 'prop-types'

import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import {Paper} from "@mui/material";

const Buttons = (props) => {
    return (
        <Grid container spacing={2} direction='column' justifyContent='space-between' alignItems='center'>
            <Paper style={{ backgroundColor: 'rgba(255, 255, 255, 0.7)', padding: '80px' }}>
                <Grid item style={{ margin: '10px 0', padding: '10px' }}>
                    <Button
                        variant='contained'
                        color='success'
                        fullWidth={true}
                        onClick={props.program}
                        disabled={props.disabled}
                    >
                        Flash firmware
                        <span style={{ fontSize: '0.8em', color: 'wheat', marginLeft: '10px' }}>Main firmware for regular use</span>
                    </Button>
                </Grid>
                <Grid item style={{ margin: '10px 0', padding: '10px' }}>
                    <Button
                        variant='contained'
                        color='warning'
                        fullWidth={true}
                        onClick={props.erase}
                        disabled={props.disabled}
                    >
                        Erase firmware
                        <span style={{ fontSize: '0.8em', color: 'wheat', marginLeft: '10px' }}>Remove existing firmware</span>
                    </Button>
                </Grid>

                <Grid item style={{ margin: '10px 0', marginTop: '44px', padding: '10px' }}>
                    <Button
                        variant='contained'
                        color='secondary'
                        fullWidth={true}
                        onClick={props.programDev}
                        disabled={props.disabled}
                    >
                        Flash Development firmware
                        <span style={{ fontSize: '0.8em', color: 'wheat', marginLeft: '10px' }}>For development purposes</span>
                    </Button>
                </Grid>
                <Grid item style={{ margin: '10px 0', padding: '10px' }}>
                    <Button
                        variant='contained'
                        color='secondary'
                        fullWidth={true}
                        onClick={props.programSniffer}
                        disabled={props.disabled}
                    >
                        Flash Sniffer firmware
                        <span style={{ fontSize: '0.8em', color: 'wheat', marginLeft: '10px' }}>For sniffing purposes</span>
                    </Button>
                </Grid>
            </Paper>
        </Grid>
    )
}

Buttons.propTypes = {
    erase: PropTypes.func,
    program: PropTypes.func,
    programDev: PropTypes.func,
    programSniffer: PropTypes.func,
    disabled: PropTypes.bool,
}

export default Buttons