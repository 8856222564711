import React from 'react'
import PropTypes from 'prop-types'

import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import dongleImg from './../images/dongle-v2.jpg'
import Box from "@mui/material/Box";


const Header = (props) => {
    return (
        <AppBar
            position='static'
            sx={{
                ...props.sx,
                background: '#30303E',
            }}
        >
            {
                <Toolbar>
                    {/* Logo Image at the start */}
                    <img src="https://portal.gt-innovation.com/images/logo.png" alt="logo" style={{maxHeight: 40}}/>
                    <Box sx={{flexGrow: 1}}/>

                    <img src={dongleImg} alt="logo" style={{maxHeight: 44}}/>
                    <Typography
                        variant='header1'
                        component='h3'

                        noWrap
                        sx={{
                            flexGrow: 0,
                            fontFamily: 'Nunito, sans-serif',
                            marginLeft: 2, // Adjust the spacing between the previous text and this one
                        }}
                    >
                        DONGLE MANAGEMENT TOOL
                    </Typography>
                </Toolbar>


            }
        </AppBar>
    )
}

Header.propTypes = {
    sx: PropTypes.object,
}

export default Header